import { Global, css } from '@emotion/react'

const styles = css`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    width: 100%;
  }
  body {
    height: 100%;
    width: 100%;
  }
  #root {
    height: 100%;
    width: 100%;
  }
`

const GlobalStyles = () => {
  return <Global styles={styles} />
}

export default GlobalStyles

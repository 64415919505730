import { createContext } from 'react'
import { useParams } from 'react-router'
import type { GetGameBySlugQuery } from '../../generated/graphql'
import { useGetGameBySlugQuery } from '../../generated/graphql'

export type GameType = GetGameBySlugQuery['getGameBySlug'] | undefined

const GameContext = createContext<GameType | undefined>(undefined)

export const GameProvider = ({ children }) => {
  const { slug } = useParams()
  const { data } = useGetGameBySlugQuery({ slug })

  return <GameContext.Provider value={data?.getGameBySlug}>{children}</GameContext.Provider>
}

export default GameContext

import type { ReactElement } from 'react'
import useLayout from '../../../hooks/useLayout'
import { StyledDrawer } from './styles'

interface Props {
  children: ReactElement
}

export const DesktopDrawer = ({ children }: Props) => {
  const { isSidebarOpen } = useLayout()

  return (
    <StyledDrawer
      anchor="left"
      open={isSidebarOpen}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          top: '64px !Important',
        },
      }}
      variant="permanent"
    >
      {children}
    </StyledDrawer>
  )
}

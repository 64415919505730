import type { Dispatch, SetStateAction } from 'react'
import { createContext, useState } from 'react'

export type Layout = {
  isDrawerOpen: boolean
  isMobileDrawerOpen: boolean
  isSidebarOpen: boolean
  isMobileSidebarOpen: boolean
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>
  setIsMobileDrawerOpen: Dispatch<SetStateAction<boolean>>
  setIsSidebarOpen: Dispatch<SetStateAction<boolean>>
  setIsMobileSidebarOpen: Dispatch<SetStateAction<boolean>>
  isCreateModalOpen: boolean
  setIsCreateModalOpen: Dispatch<SetStateAction<boolean>>
}

const initialState = {
  isDrawerOpen: false,
  isMobileDrawerOpen: false,
  isSidebarOpen: true,
  isMobileSidebarOpen: false,
  setIsDrawerOpen: () => undefined,
  setIsMobileDrawerOpen: () => undefined,
  setIsSidebarOpen: () => undefined,
  setIsMobileSidebarOpen: () => undefined,
  isCreateModalOpen: false,
  setIsCreateModalOpen: () => undefined,
}

const LayoutContext = createContext<Layout>(initialState)

export const LayoutProvider = ({ children }) => {
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)

  return (
    <LayoutContext.Provider
      value={{
        isDrawerOpen,
        setIsDrawerOpen,
        isMobileDrawerOpen,
        setIsMobileDrawerOpen,
        isSidebarOpen,
        setIsSidebarOpen,
        isMobileSidebarOpen,
        setIsMobileSidebarOpen,
        isCreateModalOpen,
        setIsCreateModalOpen,
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}

export default LayoutContext

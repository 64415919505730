import { Hidden } from '@mui/material'
import useLayout from '../../../hooks/useLayout'
import { SidebarContent } from '../SidebarContent'
import { DesktopDrawer } from '../drawer/DesktopDrawer'
import { MobileDrawer } from '../drawer/MobileDrawer'
import useSections from './useSections'

const Sidebar = () => {
  const { isSidebarOpen, isMobileSidebarOpen } = useLayout()

  const isSidebarExpanded = isSidebarOpen || isMobileSidebarOpen
  const sections = useSections(isSidebarExpanded)

  return (
    <>
      <Hidden lgUp>
        <MobileDrawer>
          <SidebarContent id={'sidebar'} sections={sections} />
        </MobileDrawer>
      </Hidden>
      <Hidden lgDown>
        <DesktopDrawer>
          <SidebarContent id={'sidebar'} sections={sections} />
        </DesktopDrawer>
      </Hidden>
    </>
  )
}

export default Sidebar

import { Outlet } from 'react-router-dom'
import type { Theme } from '@mui/material'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { RoleProvider } from '../../../contexts/PermissionContext'
import useLayout from '../../../hooks/useLayout'
import Navbar from './Navbar'
import Sidebar from './Sidebar'

const LayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}))

const LayoutWrapper = styled(Box, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'isSidebarOpen',
})<{ isSidebarOpen: boolean }>(({ theme, isSidebarOpen }: { theme: Theme; isSidebarOpen: boolean }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: '64px',
  transition: theme.transitions.create('padding', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shortest,
  }),
  [theme.breakpoints.up('lg')]: {
    paddingLeft: isSidebarOpen ? '280px' : '80px',
  },
}))

const LayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
})

const LayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch',
})

const Layout = () => {
  const { isSidebarOpen } = useLayout()

  return (
    <LayoutRoot>
      <RoleProvider>
        <Navbar />
        <Sidebar />
        <LayoutWrapper isSidebarOpen={isSidebarOpen}>
          <LayoutContainer>
            <LayoutContent>
              <Outlet />
            </LayoutContent>
          </LayoutContainer>
        </LayoutWrapper>
      </RoleProvider>
    </LayoutRoot>
  )
}

export default Layout

import { useTranslation } from 'react-i18next'
import { Card, Typography } from '@mui/material'

interface Props {
  resourceName?: string
}

const AccessDenied = ({ resourceName }: Props) => {
  const { t } = useTranslation()

  const _resourceName = resourceName || t('access.resource')

  return (
    <Card sx={{ width: '60%', py: 5, px: 2, mx: 'auto', my: 5 }} data-testid="access-denied-component">
      <Typography textAlign={'center'} data-testid="access-denied-info">
        {t('access.accessDenied', { resourceName: _resourceName })}
      </Typography>
    </Card>
  )
}

export default AccessDenied

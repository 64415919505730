export default {
  navigation: {
    dashboard: 'Panel',
    account: 'Konto',
    users: 'Użytkownik',
    settings: 'Ustawienia',
    logout: 'Wyloguj',
    profile: 'Profil',
  },
  validateSurvey: {
    buttonText: 'Waliduj ankiete',
    surveyDidNotPassValidation: 'Ankieta nie przeszła walidacji.',
    surveyIsConstructedProperly: 'Ankieta została skonstruowana poprawnie.',
    questionsOrAnswersAreWrong: 'Zabrakło niektórych pytań lub odpowiedzi albo są one źle skonstruowane.',
    allQuestionsAndAnswersPassedValidation: 'Wszystkie pytania i odpowiedzi poprawnie przeszły proces walidacji.',
    close: 'Zamknij',
    failed: 'Wystąpił problem',
    success: 'Sukces',
    adultQuestionsLine: 'Pytania dla dorosłych',
    childrenQuestionsLine: 'Pytania dla dzieci',
    adultAnswersLine: 'Odpowiedzi dorosłych',
    childrenAnswersLine: 'Odpowiedzi dzieci',
    extraQuestionsLine: 'Dodatkowe pytania',
    adultAnswers: 'Niektóre odpowiedzi dla dorosłych są niepoprawne lub ich brakuje.',
    adultQuestions: 'Niektóre pytania dla dorosłych są niepoprawne lub ich brakuje.',
    childrenAnswers: 'Niektóre odpowiedzi dla dzieci są niepoprawne lub ich brakuje.',
    childrenQuestions: 'Niektóre pytania dla dzieci są niepoprawne lub ich brakuje.',
    extraQuestions: 'Niektóre pytania dodatkowe są niepoprawne lub ich brakuje.',
    activityQuestionsMissing: 'Brak pytania o aktywność.',
    yesOrNoAnswerMissing: 'Powinno zawierać przynajmniej dwie odpowiedzi "Tak" oraz "Wolę nie odpowiadać".',
    notAllIncludeDays: 'Powinno zawierać wszystkie dni tygodnia.',
    notAllIncludeNumberOfDays: 'Wszystkie z odpowiedzi powinny zawierać liczbę dni.',
    doNotContainHourOrNumber: 'Wszystkie z odpowiedzi powinny zawierać godzinę lub minuty.',
    noDisabilityQuestion: 'Brak pytania o niepełnosprawność.',
    noMedicalConditionQuestion: 'Brak pytania o stan zdrowia.',
    noEthnicityQuestions: 'Brak pytania o pochodzenie.',
    validEthnicityAnswersMissing:
      'Odpowiedzi o pochodzeniu powinny zawierać opcje BAME, pochodzenie białe i "Wolę nie odpowiadać"',
    snackbarError: 'Wystąpił problem w trakcie walidacji ankiety.',
  },
}

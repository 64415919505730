import createSvgIcon from '@mui/material/utils/createSvgIcon'

const Teams = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -256 1950 1950" fill="currentColor">
    <path
      d="M608.186 719.898q-162 5-265 128h-134q-82 0-138-40.5t-56-118.5q0-353 124-353 6 0 43.5 21t97.5 42.5q60 21.5 119 21.5 67 0 133-23-5 37-5 66 0 139 81 256zm1071 637q0 120-73 189.5t-194 69.5h-874q-121 0-194-69.5t-73-189.5q0-53 3.5-103.5t14-109q10.5-58.5 26.5-108.5t43-97.5q27-47.5 62-81t85.5-53.5q50.5-20 111.5-20 10 0 43 21.5t73 48q40 26.5 107 48t135 21.5q68 0 135-21.5t107-48q40-26.5 73-48t43-21.5q61 0 111.5 20t85.5 53.5q35 33.5 62 81t43 97.5q16 50 26.5 108.5t14 109q3.5 50.5 3.5 103.5zm-1024-1277q0 106-75 181t-181 75q-106 0-181-75t-75-181q0-106 75-181t181-75q106 0 181 75t75 181zm704 384q0 159-112.5 271.5t-271.5 112.5q-159 0-271.5-112.5t-112.5-271.5q0-159 112.5-271.5t271.5-112.5q159 0 271.5 112.5t112.5 271.5zm576 225q0 78-56 118.5t-138 40.5h-134q-103-123-265-128 81-117 81-256 0-29-5-66 66 23 133 23 59 0 119-21.5t97.5-42.5q37.5-21 43.5-21 124 0 124 353zm-128-609q0 106-75 181t-181 75q-106 0-181-75t-75-181q0-106 75-181t181-75q106 0 181 75t75 181z"
      fill="currentColor"
    />
  </svg>,
  'Teams',
)

export default Teams

import { Link as RouterLink, useLocation } from 'react-router-dom'
import { Menu, MenuOpen } from '@mui/icons-material'
import { Box, Divider, Hidden } from '@mui/material'
import IntelligentHealth from '../../assets/intelligentHealth.svg'
import useLayout from '../../hooks/useLayout'
import Logo from '../Logo'
import Scrollbar from '../Scrollbar'
import type { NavSectionProps } from './NavSection'
import NavSection from './NavSection'

interface Props {
  id: string
  sections: Array<NavSectionProps>
}

const IntelligentHealthImage = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
      }}
    >
      <img src={IntelligentHealth} style={{ height: 'auto', width: '80%', paddingBottom: '10%' }} alt={'WalkingGirl'} />
    </Box>
  )
}

export const SidebarContent = ({ sections, id }: Props) => {
  const { isSidebarOpen, setIsSidebarOpen, isMobileSidebarOpen } = useLayout()
  const location = useLocation()

  return (
    <Box
      id={id}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        marginBottom: isMobileSidebarOpen ? 0 : '64px',
      }}
      data-testid="side-navbar"
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 2,
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: 40,
                  width: 40,
                }}
              />
            </RouterLink>
          </Box>
        </Hidden>

        <Hidden lgDown>
          {!isSidebarOpen && (
            <Box sx={{ m: 2 }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Menu onClick={() => setIsSidebarOpen(true)} />
            </Box>
          )}
        </Hidden>

        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          {isSidebarOpen && (
            <>
              <Hidden lgDown>
                <Box style={{ position: 'absolute', zIndex: 10000, top: 15, right: 15 }}>
                  <MenuOpen onClick={() => setIsSidebarOpen(false)} />
                </Box>
                <Divider />
              </Hidden>
            </>
          )}
        </Box>

        <Box sx={{ p: 2, paddingTop: 4 }}>
          {sections.map((section, index) => (
            <NavSection
              key={`${section.pathname}${index}`}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 0,
                },
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
      {isSidebarOpen && <IntelligentHealthImage />}
    </Box>
  )
}

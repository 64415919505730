import { useTranslation } from 'react-i18next'
import HelpOutlineIcon from '@mui/icons-material/InfoOutlined'
import numeral from 'numeral'
import TooltipIcon from '../../../../components/TooltipIcon'
import { useGetCardsCountQuery } from '../../../../generated/graphql'
import useGame from '../../../../hooks/useGame'
import { useTheme } from '../../../../theme'
import BasicWidget from '../../Stats/components/widgets/BasicWidget'

const TotalCardsWidget = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const game = useGame()

  const { data: cardsCountData } = useGetCardsCountQuery(
    { input: { gameId: game?.id } },
    { enabled: Boolean(game?.id) },
  )
  const cardsCount = cardsCountData?.adminGetCardsCount

  return (
    <BasicWidget
      title={`${numeral(cardsCount?.total).format('0,0')}`}
      subtitle={t('stats.totalCards')}
      chipLabel={numeral(cardsCount ? cardsCount?.total / cardsCount?.distributed : 0).format('0%')}
      chipSubtitle={t('stats.percentOfDistribution')}
      iconRight={
        <TooltipIcon
          text={t('stats.tooltips.totalCards')}
          icon={<HelpOutlineIcon fontSize={'small'} sx={{ color: theme.palette.text.secondary }} />}
        />
      }
    />
  )
}

export default TotalCardsWidget

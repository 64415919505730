import { useEffect, useRef, useState } from 'react'
import { Box, Button, Fab, FormControlLabel, Popover, Switch, Tooltip, Typography } from '@mui/material'
import type { Settings } from '../../contexts/SettingsContext'
import useSettings from '../../hooks/useSettings'
import AdjustmentsIcon from '../../icons/Adjustments'

const getValues = (settings: Settings) => ({
  compact: settings.compact,
  direction: settings.direction,
  responsiveFontSizes: settings.responsiveFontSizes,
  roundedCorners: settings.roundedCorners,
  theme: settings.theme,
})

const SettingsFab = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null)
  const { settings, saveSettings } = useSettings()
  const [open, setOpen] = useState<boolean>(false)
  const [values, setValues] = useState(getValues(settings))

  useEffect(() => {
    setValues(getValues(settings))
  }, [settings])

  const handleOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  const handleChange = (field: string, value: any): void => {
    setValues({
      ...values,
      [field]: value,
    })
  }

  const handleSave = (): void => {
    saveSettings(values)
    setOpen(false)
  }

  return (
    <>
      <Tooltip title="Settings">
        <Fab
          color="primary"
          onClick={handleOpen}
          ref={anchorRef}
          size="medium"
          sx={{
            bottom: 0,
            margin: (theme) => theme.spacing(4),
            position: 'fixed',
            right: 0,
            zIndex: (theme) => theme.zIndex.speedDial,
          }}
        >
          <AdjustmentsIcon fontSize="small" />
        </Fab>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            p: 2,
            width: 320,
          },
        }}
      >
        <Typography color="textPrimary" variant="h6">
          Settings
        </Typography>
        {/*<Box sx={{ mt: 3 }}>*/}
        {/*  <TextField*/}
        {/*    fullWidth*/}
        {/*    label="Theme"*/}
        {/*    name="theme"*/}
        {/*    onChange={(event): void => handleChange('theme', event.target.value)}*/}
        {/*    select*/}
        {/*    SelectProps={{ native: true }}*/}
        {/*    value={values.theme}*/}
        {/*    variant="outlined"*/}
        {/*  >*/}
        {/*    {Object.keys(THEMES).map((theme) => (*/}
        {/*      <option key={theme} value={theme}>*/}
        {/*        {theme*/}
        {/*          .split('_')*/}
        {/*          .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())*/}
        {/*          .join(' ')}*/}
        {/*      </option>*/}
        {/*    ))}*/}
        {/*  </TextField>*/}
        {/*</Box>*/}
        <Box
          sx={{
            mt: 2,
            px: 1.5,
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={values.responsiveFontSizes}
                color="primary"
                edge="start"
                name="direction"
                onChange={(event): void => handleChange('responsiveFontSizes', event.target.checked)}
              />
            }
            label="Responsive font sizes"
          />
        </Box>
        <Box
          sx={{
            mt: 2,
            px: 1.5,
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={values.compact}
                color="primary"
                edge="start"
                name="compact"
                onChange={(event): void => handleChange('compact', event.target.checked)}
              />
            }
            label="Compact"
          />
        </Box>
        <Box
          sx={{
            mt: 2,
            px: 1.5,
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={values.roundedCorners}
                color="primary"
                edge="start"
                name="roundedCorners"
                onChange={(event): void => handleChange('roundedCorners', event.target.checked)}
              />
            }
            label="Rounded Corners"
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <Button color="primary" fullWidth onClick={handleSave} variant="contained">
            Save Settings
          </Button>
        </Box>
      </Popover>
    </>
  )
}

export default SettingsFab

import { Auth } from 'aws-amplify'
import env from '../env'
import localAuth from '../utils/localAuth'

export const fetchData = async <TData, TVariables>(
  query: string,
  variables?: TVariables,
  options?: RequestInit['headers'],
): Promise<TData> => {
  const headers: Record<string, string> = {}
  try {
    const session = await Auth.currentSession()
    headers.Authorization = session.getAccessToken().getJwtToken()
  } catch (e) {
    if (env.local) {
      const username = localAuth.getUsername()
      if (username) {
        headers.Authorization = username
      }
    } else {
      headers.Authorization = 'none'
    }
  }

  const res = await fetch(`${env.apiUrl}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
      ...options,
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  })

  const json = await res.json()

  if (json.errors) {
    const error = json.errors[0] || {}
    console.error(error)
    throw new Error(error.message || 'Error...')
  }

  return json.data
}

export const fetchGenerated = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  options?: RequestInit['headers'],
): (() => Promise<TData>) => {
  return () => fetchData(query, variables, options)
}

import { Link, Typography } from '@mui/material'
import Arrow from '../icons/ArrowRight'

interface Props {
  to: string
  text: string
}

const Redirecting = ({ to, text }: Props) => {
  return (
    <Link
      color={'primary'}
      href={to}
      variant={'inherit'}
      underline={'none'}
      py={2}
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <Typography pr={1} variant={'subtitle2'}>
        {text}
      </Typography>
      <Arrow sx={{ fontSize: 16 }} />
    </Link>
  )
}

export default Redirecting

import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Amplify } from 'aws-amplify'
import App from './App'
import AuthProvider from './contexts/AuthContext'
import { LayoutProvider } from './contexts/LayoutContext'
import { SettingsProvider } from './contexts/SettingsContext'
import env from './env'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'
import './validators'

Amplify.configure({
  aws_user_pools_web_client_id: env.cognitoClientId,
  aws_user_pools_id: env.cognitoPoolId,
  aws_project_region: 'eu-west-1',
  aws_cognito_region: 'eu-west-1',
})

const queryClient = new QueryClient()

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <HelmetProvider>
    <StyledEngineProvider injectFirst>
      <QueryClientProvider client={queryClient}>
        <LayoutProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              <BrowserRouter>
                <AuthProvider>
                  <App />
                </AuthProvider>
              </BrowserRouter>
            </SettingsProvider>
          </LocalizationProvider>
        </LayoutProvider>
      </QueryClientProvider>
    </StyledEngineProvider>
  </HelmetProvider>,
)

// If you want to enable client cache, register instead.
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

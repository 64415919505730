import { useState } from 'react'
import { useParams } from 'react-router'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import {
  Avatar,
  Box,
  Button,
  Hidden,
  IconButton,
  Link,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { differenceInMinutes } from 'date-fns'
import startCase from 'lodash/startCase'
import GamePlaceholder from '../../../assets/GamePlaceholder.svg'
import type { Game } from '../../../generated/graphql'
import { GameType, useGetGameBySlugQuery } from '../../../generated/graphql'
import useGames from '../../../hooks/useGames/useGames'
import useLayout from '../../../hooks/useLayout'
import Arrow from '../../../icons/ArrowRight'
import MenuIcon from '../../../icons/Menu'
import { useTheme } from '../../../theme'
import Logo from '../../Logo'

const statusesValue = {
  draft: 1,
  live: 3,
  anticipation: 2,
  sustain: 4,
  finished: 5,
}

const NavbarMenu = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { slug } = useParams()
  const { data: gameData } = useGetGameBySlugQuery({ slug }, { enabled: Boolean(slug) })
  const game = gameData?.getGameBySlug
  const theme = useTheme()
  const { setIsMobileSidebarOpen } = useLayout()
  const navigate = useNavigate()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  const allGames = useGames()

  const sortingStatus = (a: Game, b: Game) => {
    return statusesValue[a.info.status] - statusesValue[b.info.status]
  }

  const sortDates = (a: Game, b: Game) => {
    return differenceInMinutes(new Date(a.info.startsAt), new Date(b.info.startsAt))
  }

  const sorted = allGames ? [...allGames]?.sort(sortDates).sort(sortingStatus) : []

  const games = sorted?.slice(0, 3) || []

  const headerNavPadding = matches ? 16 : 24

  return (
    <Box
      sx={{
        width: 280 - headerNavPadding,
        borderRight: `1px solid ${theme.colors.headerDividers}`,
        height: '64px',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Hidden lgUp>
        <IconButton
          color="inherit"
          onClick={() => setIsMobileSidebarOpen((prevState) => !prevState)}
          sx={{ width: '45px', height: '45px' }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
      </Hidden>
      <Hidden lgDown>
        <RouterLink to={'/'}>
          <Logo
            sx={{
              height: 45,
              width: 45,
            }}
            color={theme.colors.header}
          />
        </RouterLink>
      </Hidden>

      <Button
        variant={'text'}
        sx={{
          width: '100%',
          height: '100%',
          py: 0,
          px: 2,
          color: theme.palette.primary.contrastText,
          justifyContent: 'space-between',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', width: `calc(100% - 24px)`, pr: 1 }}>
          <Typography
            variant={'body2'}
            textAlign={'left'}
            sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }}
          >
            {slug ? game?.info.name : 'All games'}
          </Typography>
          {slug ? (
            <Typography
              variant={'caption'}
              sx={{ display: 'block', color: game?.info.type === GameType.Compact ? '#FFF579' : '#FFFFFF' }}
            >
              {startCase(game?.info.type)}
            </Typography>
          ) : null}
        </Box>

        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </Button>
      {isOpen && (
        <Box
          sx={{
            position: 'absolute',
            top: '64px',
            backgroundColor: theme.colors.header,
            width: 280,
            left: -headerNavPadding,
            borderTop: `1px solid ${theme.colors.headerDividers}`,
          }}
        >
          <List>
            {games?.map((game) => (
              <ListItemButton
                key={game.id}
                sx={{
                  px: 3,
                  '&:hover': {
                    backgroundColor: theme.colors.darkBlue,
                  },
                }}
                onClick={() => {
                  navigate(`/games/${game.info.slug}/overview`)
                  setIsOpen(false)
                }}
              >
                <ListItemAvatar>
                  <Avatar src={GamePlaceholder} />
                </ListItemAvatar>
                <ListItemText primary={game.info.name} />
              </ListItemButton>
            ))}
          </List>

          <Link
            color={theme.palette.primary.contrastText}
            component={RouterLink}
            to={'/games/'}
            variant={'inherit'}
            underline={'none'}
            onClick={() => setIsOpen(false)}
            py={1}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              borderTop: `1px solid ${theme.colors.headerDividers}`,
              px: 3,
              py: 2,
            }}
          >
            <Typography pr={1} variant={'subtitle2'}>
              See all games
            </Typography>
            <Arrow sx={{ fontSize: 16 }} />
          </Link>
        </Box>
      )}
    </Box>
  )
}

export default NavbarMenu

import { useTranslation } from 'react-i18next'
import HelpOutlineIcon from '@mui/icons-material/InfoOutlined'
import numeral from 'numeral'
import TooltipIcon from '../../../../components/TooltipIcon'
import { useGetGameMileageQuery, useGetPlayerCountQuery } from '../../../../generated/graphql'
import useGame from '../../../../hooks/useGame'
import { useTheme } from '../../../../theme'
import BasicWidget from '../../Stats/components/widgets/BasicWidget'

const GameMileageWidget = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const game = useGame()

  const { data: playerCountData } = useGetPlayerCountQuery({ input: { gameId: game?.id } }, { enabled: !!game?.id })
  const playerCount = playerCountData?.adminGetPlayerCount

  const { data: gameMileageData } = useGetGameMileageQuery(
    { input: { gameId: game?.id } },
    { enabled: Boolean(game?.id) },
  )
  const gameMileage = gameMileageData?.adminGetGameMileage

  return (
    <BasicWidget
      title={`${numeral(gameMileage?.mileage || 0).format('0,0')}`}
      subtitle={t('stats.milesCovered')}
      iconRight={
        <TooltipIcon
          text={t('stats.tooltips.mileage', { distanceSetting: '0.5' })}
          icon={<HelpOutlineIcon fontSize={'small'} sx={{ color: theme.palette.text.secondary }} />}
        />
      }
      chipLabel={`${numeral(gameMileage?.mileage / playerCount?.total).format('0,0')} miles`}
      chipSubtitle={t('stats.milesPerPlayer')}
    />
  )
}

export default GameMileageWidget

import type { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'

interface GuestGuardProps {
  children?: ReactNode
}

const GuestGuard = ({ children }: GuestGuardProps) => {
  const { isAuthenticated } = useAuth()

  if (isAuthenticated) {
    return <Navigate to="/" />
  }

  return <>{children}</>
}

export default GuestGuard

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import HelpOutlineIcon from '@mui/icons-material/InfoOutlined'
import TooltipIcon from '../../../../components/TooltipIcon'
import { useGetSwipesFrequenciesDailyQuery } from '../../../../generated/graphql'
import useGame from '../../../../hooks/useGame'
import { useTheme } from '../../../../theme'
import BasicWidget from '../../Stats/components/widgets/BasicWidget'

const FrequencyDayWidget = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const game = useGame()

  const { data: swipeFrequencyDailyData } = useGetSwipesFrequenciesDailyQuery(
    { input: { gameId: game?.id } },
    { enabled: !!game?.id },
  )
  const swipesFrequenciesDaily = swipeFrequencyDailyData?.adminGetSwipesFrequenciesDaily

  const mostSwipesDay = useMemo(
    () =>
      swipesFrequenciesDaily &&
      Math.max(...(Object.values(swipesFrequenciesDaily).filter((n) => n !== 'SwipesFrequenciesDaily') as any[])),
    [swipesFrequenciesDaily],
  )
  const highestDay =
    swipesFrequenciesDaily &&
    Object.keys(swipesFrequenciesDaily).find((key) => swipesFrequenciesDaily[key] === mostSwipesDay)

  const leastSwipesDay = useMemo(
    () =>
      swipesFrequenciesDaily &&
      Math.min(...(Object.values(swipesFrequenciesDaily).filter((n) => n !== 'SwipesFrequenciesDaily') as any[])),
    [swipesFrequenciesDaily],
  )
  const lowestDay =
    swipesFrequenciesDaily &&
    Object.keys(swipesFrequenciesDaily).find((key) => swipesFrequenciesDaily[key] === leastSwipesDay)

  return (
    <BasicWidget
      title={highestDay}
      subtitle={t('stats.highestFrequencyDay')}
      iconRight={
        <TooltipIcon
          text={t('stats.tooltips.frequencyDay')}
          icon={<HelpOutlineIcon fontSize={'small'} sx={{ color: theme.palette.text.secondary }} />}
        />
      }
      chipLabel={lowestDay}
      chipSubtitle={t('stats.lowestFrequencyDay')}
    />
  )
}

export default FrequencyDayWidget

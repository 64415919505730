import type { Action, InitializeAction, LoginAction, State } from './type'

export const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user: user,
    }
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload

    return {
      ...state,
      isAuthenticated: true,
      user: user,
    }
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: undefined,
  }),
  REGISTER: (state: State): State => ({ ...state }),
  VERIFY_CODE: (state: State): State => ({ ...state }),
  RESEND_CODE: (state: State): State => ({ ...state }),
  PASSWORD_RECOVERY: (state: State): State => ({ ...state }),
  PASSWORD_RESET: (state: State): State => ({ ...state }),
  COMPLETE_NEW_PASSWORD: (state: State): State => ({ ...state }),
}

export const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state

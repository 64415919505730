import { createContext, useContext, useMemo } from 'react'
import useAuth from '../../hooks/useAuth'
import useGame from '../../hooks/useGame'

type Role = {
  isGameAvailable: (gameId: string) => boolean
  getGameRole: (gameId: string) => RoleType
  hasGame: boolean
  role: RoleType
  isAdmin: boolean
}

const init: Role = {
  isGameAvailable: () => false,
  getGameRole: () => undefined,
  hasGame: false,
  role: 'reporter',
  isAdmin: false,
}

const RoleContext = createContext<Role>(init)

const allPolicies = ['allowFullAdmin', 'basicPartner', 'allowAdminGame', 'readAdminGame']
export type RoleType = 'admin' | 'basicPartner' | 'reporter'

const mapPolicyToRoles = (policy: string): RoleType => {
  switch (policy) {
    case 'basicPartner':
      return 'basicPartner'
    case 'readAdminGame':
      return 'reporter'
    case 'allowFullAdmin':
      return 'admin'
    case 'allowAdminGame':
      return 'admin'
    default:
      return undefined
  }
}

export const RoleProvider = ({ children }) => {
  const { user } = useAuth()
  const game = useGame()

  const isGameAvailable = (gameId?: string) => {
    return !!user?.role.policies?.find(({ name, options }) => {
      if ('allowFullAdmin' === name) return true
      return allPolicies.includes(name) && options?.gameId === gameId
    })
  }

  const getGameRole = (gameId?: string): RoleType => {
    if (user?.role.isAdmin) {
      return 'admin'
    }

    if (!gameId) return 'reporter'
    const selected = user?.role.policies?.find(({ options }) => {
      return options?.gameId === gameId
    })
    if (selected) {
      return mapPolicyToRoles(selected.name)
    }
    return 'reporter'
  }

  const role = useMemo(() => {
    return getGameRole(game?.id)
  }, [getGameRole, game?.id])

  const hasGame = useMemo(() => {
    return !!user?.role.policies.find((policy) => allPolicies.includes(policy.name))
  }, [user?.role.policies])

  return (
    <RoleContext.Provider
      value={{
        isGameAvailable,
        getGameRole,
        hasGame: hasGame,
        role,
        isAdmin: user.role.isAdmin,
      }}
    >
      {children}
    </RoleContext.Provider>
  )
}

export const useRole = () => useContext(RoleContext)

export default RoleContext

import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import HelpOutlineIcon from '@mui/icons-material/InfoOutlined'
import {
  Box,
  Card,
  CircularProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import Redirecting from '../../../../components/Redirecting'
import TooltipIcon from '../../../../components/TooltipIcon'
import { useGetLocationFrequencyQuery } from '../../../../generated/graphql'
import useGame from '../../../../hooks/useGame'
import { useTheme } from '../../../../theme'
import { toUrlId } from '../../../../utils/toUrlId'

const LocationsTable = () => {
  const game = useGame()
  const { t } = useTranslation()
  const theme = useTheme()

  const { data: locationFrequencyData, isLoading } = useGetLocationFrequencyQuery(
    { input: { gameId: game?.id } },
    { enabled: !!game?.id },
  )
  const locationFrequency = locationFrequencyData?.adminGetLocationFrequency

  const locations = locationFrequency?.slice(0, 5)

  return (
    <>
      <Card
        sx={{
          overflow: 'visible',
        }}
      >
        <Box sx={{ display: 'flex', p: 2 }}>
          <Typography color="inherit" variant="body2" textTransform={'uppercase'}>
            {t('stats.locationFrequency.title')}
          </Typography>
          <Box sx={{ pl: 1 }}>
            <TooltipIcon
              text={t('stats.tooltips.locationFrequency')}
              icon={<HelpOutlineIcon fontSize={'small'} sx={{ color: theme.palette.text.secondary }} />}
            />
          </Box>
        </Box>
        <Box>
          {isLoading && (
            <Box sx={{ ml: 2, display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
              <CircularProgress />
            </Box>
          )}
          {!isLoading && !locations?.length && (
            <Typography ml={2} color="inherit" variant="body2">
              {t('stats.locationFrequency.noLocations')}
            </Typography>
          )}
          {locations?.length > 0 && (
            <Table data-test-id={'eventsScheduleTable'}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('stats.locationFrequency.id')}</TableCell>
                  <TableCell>{t('stats.locationFrequency.description')}</TableCell>
                  <TableCell>{t('stats.locationFrequency.visits')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {locations?.map((location) => {
                  return (
                    <TableRow hover key={location.id}>
                      <TableCell>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                          }}
                        >
                          <Box sx={{ ml: 1 }}>
                            <Link
                              component={RouterLink}
                              to={`/games/${game?.info.slug}/mapping?locationId=${toUrlId(location.id)}`}
                              variant={'subtitle2'}
                              sx={{ fontWeight: 'bold', textDecoration: 'none' }}
                            >
                              {location.slug}
                            </Link>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography color="inherit" variant="body2">
                          {location.description}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography color="inherit" variant="body2">
                            {location.visits}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          )}
          <Box sx={{ ml: 2 }}>
            <Redirecting to={`/games/${game?.info.slug}/mapping`} text={t('stats.seeLocations')} />
          </Box>
        </Box>
      </Card>
    </>
  )
}

export default LocationsTable

import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import useAuth from '../../../hooks/useAuth'
import UserIcon from '../../../icons/User'
import { toUrlId } from '../../../utils/toUrlId'

const AccountPopover = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null)
  const { user, logout } = useAuth()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = useState<boolean>(false)
  const { t } = useTranslation()

  const isAdmin = user?.role.isAdmin
  const handleOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose()
      await logout()
      navigate('/')
    } catch (err) {
      console.error(err)
      enqueueSnackbar(t('errors.logout'), {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
        variant: 'error',
      })
    }
  }

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
        data-testid="header-user-avatar"
      >
        <Avatar
          id={'profile-avatar'}
          // src={user.avatar}
          sx={{
            height: 32,
            width: 32,
          }}
        />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {user?.info.publicName}
          </Typography>
          <Typography color="textSecondary" variant="subtitle2">
            {`BTS - ${isAdmin ? "'ADMIN'" : "'MANAGER'"}`}
          </Typography>
        </Box>
        <Divider />
        {isAdmin && (
          <Box sx={{ mt: 2 }}>
            <MenuItem
              component={RouterLink}
              to={`/admin/members/edit/${toUrlId(user?.id)}`}
              onClick={handleClose}
              data-testid="profile-button"
            >
              <ListItemIcon>
                <UserIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography color="textPrimary" variant="subtitle2">
                    {t('navigation.profile')}
                  </Typography>
                }
              />
            </MenuItem>
          </Box>
        )}

        <Box sx={{ p: 2 }}>
          <Button color="primary" fullWidth onClick={handleLogout} variant="outlined">
            {t('navigation.logout')}
          </Button>
        </Box>
      </Popover>
    </>
  )
}

export default AccountPopover

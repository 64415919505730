import { useEffect } from 'react'
import { useRoutes } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import { SnackbarProvider } from 'notistack'
import GlobalStyles from './components/global/GlobalStyles'
import SettingsFab from './components/global/SettingsFab'
import SplashScreen from './components/global/SplashScreen'
import useAuth from './hooks/useAuth'
import useScrollReset from './hooks/useScrollReset'
import useSettings from './hooks/useSettings'
import './i18n'
import gtm from './lib/gtm'
import routes from './routes'
import { createTheme } from './theme'

const App = () => {
  useScrollReset()
  const content = useRoutes(routes)
  const { settings } = useSettings()
  const { isInitialized } = useAuth()

  useEffect(() => {
    gtm.initialize(process.env.REACT_APP_GTM_ID)
  }, [])

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  })

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider dense maxSnack={3}>
        <GlobalStyles />
        <SettingsFab />
        {isInitialized ? content : <SplashScreen />}
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default App

import { AppBar, Box, Toolbar } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTheme } from '../../../theme'
import AccountPopover from '../popover/AccountPopover'
import NavbarMenu from './NavbarMenu'

const NavbarRoot = styled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
  }),
  zIndex: theme.zIndex.drawer + 100,
})) as typeof AppBar

const Navbar = () => {
  const theme = useTheme()

  return (
    <NavbarRoot>
      <Toolbar
        sx={{
          minHeight: 64,
          background: theme.colors.header,
        }}
      >
        <NavbarMenu />
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </NavbarRoot>
  )
}

export default Navbar

import { createContext, useEffect, useReducer } from 'react'
import { Auth } from 'aws-amplify'
import { fetchData } from '../../client'
import { THEMES } from '../../constants'
import env from '../../env'
import type {
  AuthPlayerQuery,
  AuthPlayerQueryVariables,
  LocalPlayerQuery,
  LocalPlayerQueryVariables,
} from '../../generated/graphql'
import { AuthPlayerDocument, LocalPlayerDocument } from '../../generated/graphql'
import useSettings from '../../hooks/useSettings'
import localAuth from '../../utils/localAuth'
import type { Settings } from '../SettingsContext'
import { reducer } from './reducer'
import type { AuthContextValue, ICognitoUser, State } from './type'

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: undefined,
}

const getValues = (settings: Settings) => ({
  compact: settings.compact,
  direction: settings.direction,
  responsiveFontSizes: settings.responsiveFontSizes,
  roundedCorners: settings.roundedCorners,
  theme: settings.theme,
})

export const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
})

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { settings, saveSettings } = useSettings()

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        let username: string
        try {
          const user = await Auth.currentAuthenticatedUser({ bypassCache: true })
          username = user.getUsername()
        } catch (e) {
          if (env.local) {
            username = localAuth.getUsername() || ''
          } else {
            throw e
          }
        }

        const res = await fetchData<AuthPlayerQuery, AuthPlayerQueryVariables>(AuthPlayerDocument, {
          authID: username,
        })

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: res?.getPlayerByAuth,
          },
        })

        saveSettings({
          ...getValues(settings),
          theme: THEMES.MAIN,
        })
      } catch (error) {
        console.error(error)
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: undefined,
          },
        })
      }
    }

    initialize()
  }, [])

  const login = async (email: string, password: string): Promise<void | ICognitoUser> => {
    if (env.local) return localLogin(email, password)
    const user = (await Auth.signIn(email, password)) as ICognitoUser

    if (user.challengeName) {
      console.error(
        `Unable to login, because challenge '${user.challengeName}' is mandated and the case is not handled.`,
      )
      return
    }

    const res = await fetchData<AuthPlayerQuery, AuthPlayerQueryVariables>(AuthPlayerDocument, {
      authID: user.getUsername(),
    })

    saveSettings({
      ...getValues(settings),
      theme: THEMES.MAIN,
    })

    dispatch({
      type: 'LOGIN',
      payload: {
        user: res?.getPlayerByAuth,
      },
    })
  }

  const localLogin = async (email: string, authId: string) => {
    localAuth.setUsername(authId)
    const res = await fetchData<LocalPlayerQuery, LocalPlayerQueryVariables>(LocalPlayerDocument, {
      email: email,
    })
    dispatch({
      type: 'LOGIN',
      payload: {
        user: res?.getPlayerByEmail,
      },
    })
  }
  const localLogout = () => {
    localAuth.removeUsername()
    dispatch({
      type: 'LOGOUT',
    })
  }

  const logout = async (): Promise<void> => {
    if (env.local) return localLogout()
    await Auth.signOut()
    dispatch({
      type: 'LOGOUT',
    })
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider

import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import translationEN from './locales/en'
import translationPL from './locales/pl'

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      pl: {
        translation: translationPL,
      },
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })

import type { ReactNode } from 'react'
import { Box, Card, CardContent, Divider, Typography, useTheme, Chip } from '@mui/material'

interface BasicWidgetProps {
  title: string
  subtitle?: string
  chipLabel?: string
  chipSubtitle?: string
  iconLeft?: ReactNode
  iconRight?: ReactNode
  titleFontSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  bottomContent?: ReactNode
}

const BasicWidget = ({
  title,
  subtitle,
  chipLabel,
  chipSubtitle,
  iconLeft,
  iconRight,
  titleFontSize = 'h4',
  bottomContent,
}: BasicWidgetProps) => {
  const theme = useTheme()
  return (
    <Card
      elevation={1}
      sx={{
        borderRadius: 2,
        // height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          justifyContent: iconLeft ? (iconRight ? 'space-between' : 'flex-start') : 'space-between',
          alignItems: 'flex-start',
          p: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          {iconLeft && <Box mr={2}>{iconLeft}</Box>}
          <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Typography sx={{ textTransform: 'capitalize' }} variant={titleFontSize}>
                {title}
              </Typography>
              {iconRight && <Box sx={{ ml: 2 }}>{iconRight}</Box>}
            </Box>

            {subtitle && (
              <Typography pt={1} pb={1} variant={'subtitle2'} fontWeight={'bold'} color={theme.palette.text.secondary}>
                {subtitle}
              </Typography>
            )}
            {chipLabel && <Chip sx={{ mt: 1, textTransform: 'capitalize' }} color={'primary'} label={chipLabel} />}
            {chipSubtitle && (
              <Typography pt={1} variant={'subtitle2'} color={theme.palette.text.secondary}>
                {chipSubtitle}
              </Typography>
            )}
          </Box>
        </Box>
      </CardContent>
      {bottomContent && (
        <Box>
          <Divider />
          <Box px={3} sx={{ display: 'flex' }}>
            {bottomContent}
          </Box>
        </Box>
      )}
    </Card>
  )
}

export default BasicWidget

export const LOCAL_USERNAME = 'localUsername'

class LocalAuth {
  getUsername(): string | null {
    return localStorage.getItem(LOCAL_USERNAME)
  }

  setUsername(value: string) {
    localStorage.setItem(LOCAL_USERNAME, value)
  }

  removeUsername() {
    localStorage.removeItem(LOCAL_USERNAME)
  }
}

const localAuth = new LocalAuth()

export default localAuth

import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { matchPath } from 'react-router'
import { useLocation } from 'react-router-dom'
import {
  CreditCard,
  Dashboard,
  EventNote,
  GroupWork,
  LocationOn,
  People,
  Settings,
  Leaderboard,
  Assessment,
  CalendarMonth,
  Public,
  DirectionsBike,
  QueryStats,
  Diversity3,
  FormatListNumbered,
  DisplaySettings,
  EventAvailable,
} from '@mui/icons-material'
import type { TFunction } from 'i18next'
import { useRole } from '../../../contexts/PermissionContext'
import Teams from '../../../icons/Teams'

type NavigationItem = {
  title: string
  icon?: React.ReactNode
  path?: string
  children?: NavigationItem[]
}

type Navigation = {
  items: NavigationItem[]
}

const generalSections = (t: TFunction, prefix: string): Navigation[] => [
  {
    items: [
      {
        title: t('navigation.dashboard'),
        path: `${prefix}`,
        icon: <Dashboard fontSize="small" />,
      },
      {
        title: t('navigation.games'),
        path: `/games`,
        icon: <GroupWork fontSize="small" />,
      },
      {
        title: t('navigation.players'),
        path: `/players`,
        icon: <People fontSize="small" />,
      },
      {
        title: t('navigation.timelines'),
        path: `/timeline`,
        icon: <EventNote fontSize="small" />,
      },
      {
        title: t('navigation.members'),
        path: `${prefix}/members`,
        icon: <People fontSize="small" />,
      },
      {
        title: t('navigation.cardOrders'),
        path: `${prefix}/card-orders`,
        icon: <CreditCard fontSize="small" />,
      },
    ],
  },
]

const generalSectionPartner = (t: TFunction): Navigation[] => [
  {
    items: [
      {
        title: t('navigation.games'),
        path: `/games`,
        icon: <GroupWork fontSize="small" />,
      },
      {
        title: t('navigation.players'),
        path: `/players`,
        icon: <People fontSize="small" />,
      },
      {
        title: t('navigation.timelines'),
        path: `/timeline`,
        icon: <EventNote fontSize="small" />,
      },
    ],
  },
]

const generalSectionEmpty = (t: TFunction): Navigation[] => [
  {
    items: [
      {
        title: t('navigation.games'),
        path: `/games`,
        icon: <GroupWork fontSize="small" />,
      },
    ],
  },
]

const gameSections = (t: TFunction, prefix: string): Navigation[] => {
  return [
    {
      items: [
        {
          title: t('navigation.overview'),
          path: `${prefix}/overview`,
          icon: <Assessment fontSize="small" />,
        },
      ],
    },
    {
      items: [
        {
          title: t('navigation.analytics'),
          icon: <QueryStats fontSize="small" />,
          children: [
            {
              title: t('navigation.demographics'),
              path: `${prefix}/demographics`,
              icon: <Diversity3 fontSize="small" />,
            },
            {
              title: t('navigation.teams'),
              path: `${prefix}/analytics-teams`,
              icon: <Teams fontSize="small" />,
            },
            {
              title: t('navigation.activity'),
              path: `${prefix}/activity`,
              icon: <DirectionsBike fontSize="small" />,
            },
            {
              title: t('navigation.mapping'),
              path: `${prefix}/mapping-analytics`,
              icon: <Public fontSize="small" />,
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          title: t('navigation.engagement'),
          icon: <EventAvailable fontSize="small" />,
          children: [
            {
              title: t('navigation.events'),
              path: `${prefix}/events`,
              icon: <CalendarMonth fontSize="small" />,
            },
            {
              title: t('navigation.surveys'),
              path: `${prefix}/surveysPage`,
              icon: <FormatListNumbered fontSize="small" />,
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          title: t('navigation.setup'),
          icon: <DisplaySettings fontSize="small" />,
          children: [
            {
              title: t('navigation.players'),
              path: `${prefix}/players`,
              icon: <People fontSize="small" />,
            },
            {
              title: t('navigation.teams'),
              path: `${prefix}/teams`,
              icon: <Teams fontSize="small" />,
            },
            {
              title: t('navigation.cards'),
              path: `${prefix}/cards/game-ranges`,
              icon: <CreditCard fontSize="small" />,
            },
            {
              title: t('navigation.locations'),
              path: `${prefix}/mapping`,
              icon: <LocationOn fontSize="small" />,
            },
            {
              title: t('navigation.leaderboards'),
              path: `${prefix}/leaderboards`,
              icon: <Leaderboard fontSize="small" />,
            },
            {
              title: t('navigation.settings'),
              path: `${prefix}/general`,
              icon: <Settings fontSize="small" />,
            },
          ],
        },
      ],
    },
  ]
}

const gameSectionsPartner = (t, prefix) => {
  return [
    {
      items: [
        {
          title: t('navigation.overview'),
          path: `${prefix}/overview`,
          icon: <Assessment fontSize="small" />,
        },
      ],
    },
    {
      items: [
        {
          title: t('navigation.analytics'),
          icon: <QueryStats fontSize="small" />,
          children: [
            {
              title: t('navigation.demographics'),
              path: `${prefix}/demographics`,
              icon: <Diversity3 fontSize="small" />,
            },
            {
              title: t('navigation.teams'),
              path: `${prefix}/analytics-teams`,
              icon: <Teams fontSize="small" />,
            },
            {
              title: t('navigation.activity'),
              path: `${prefix}/activity`,
              icon: <DirectionsBike fontSize="small" />,
            },
            {
              title: t('navigation.mapping'),
              path: `${prefix}/mapping-analytics`,
              icon: <Public fontSize="small" />,
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          title: t('navigation.engagement'),
          icon: <EventAvailable fontSize="small" />,
          children: [
            {
              title: t('navigation.events'),
              path: `${prefix}/events`,
              icon: <CalendarMonth fontSize="small" />,
            },
            {
              title: t('navigation.surveys'),
              path: `${prefix}/surveysPage`,
              icon: <FormatListNumbered fontSize="small" />,
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          title: 'Setup',
          icon: <DisplaySettings fontSize="small" />,
          children: [
            {
              title: t('navigation.players'),
              path: `${prefix}/players`,
              icon: <People fontSize="small" />,
            },
            {
              title: t('navigation.teams'),
              path: `${prefix}/teams`,
              icon: <Teams fontSize="small" />,
            },
            {
              title: t('navigation.locations'),
              path: `${prefix}/location`,
              icon: <LocationOn fontSize="small" />,
            },
            {
              title: t('navigation.leaderboards'),
              path: `${prefix}/leaderboards`,
              icon: <Leaderboard fontSize="small" />,
            },
            {
              title: t('navigation.settings'),
              path: `${prefix}/general`,
              icon: <Settings fontSize="small" />,
            },
          ],
        },
      ],
    },
  ]
}

const useSections = (isOpen: boolean): Navigation[] => {
  const { t } = useTranslation()
  const location = useLocation()
  const matched = matchPath('games/:slug/*', location.pathname)
  const prefix = '/admin'
  const { role, hasGame } = useRole()

  const getNavigation = useMemo(() => {
    if (role === 'admin' && matched) return gameSections(t, `games/${matched.params.slug}`)
    if (role === 'admin' && !matched) return generalSections(t, prefix)
    if (matched) return gameSectionsPartner(t, `games/${matched.params.slug}`)

    return !hasGame ? generalSectionEmpty(t) : generalSectionPartner(t)
  }, [hasGame, matched, role, t])

  const mapNavigationItem = useCallback(
    (items: Navigation['items']) => {
      return {
        items: items.map(
          (item): NavigationItem => ({
            ...item,
            title: isOpen ? item.title : '',
            children: isOpen ? item.children : undefined,
          }),
        ),
      }
    },
    [isOpen],
  )

  return useMemo(() => {
    return getNavigation.map(({ items }) => mapNavigationItem(items))
  }, [getNavigation, mapNavigationItem])
}

export default useSections

import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Card, CardContent, Container, Typography } from '@mui/material'
import BackgroundContainer from '../../../components/BackgrounContainer'
import Logo from '../../../components/Logo'
import gtm from '../../../lib/gtm'
import AmplifyLogin from './AmplifyLogin'

const Login = () => {
  const { t } = useTranslation()
  useEffect(() => {
    gtm.push({ event: 'page_view' })
  }, [])

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <BackgroundContainer />
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Container maxWidth="sm" sx={{ py: '80px', zIndex: 1 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 8,
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: 60,
                  width: 60,
                }}
              />
            </RouterLink>
          </Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: 400,
                p: 4,
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3,
                }}
              >
                <div>
                  <Typography color="textPrimary" gutterBottom variant="h4">
                    {t('auth.signin')}
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    {t('auth.signinSub')}
                  </Typography>
                </div>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3,
                }}
              >
                <AmplifyLogin />
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  )
}

export default Login

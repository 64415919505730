import type { ReactElement } from 'react'
import { Drawer } from '@mui/material'
import useLayout from '../../../hooks/useLayout'

interface Props {
  children: ReactElement
}

export const MobileDrawer = ({ children }: Props) => {
  const { isMobileSidebarOpen, setIsMobileSidebarOpen } = useLayout()
  return (
    <Drawer
      anchor="left"
      onClose={() => setIsMobileSidebarOpen(false)}
      open={isMobileSidebarOpen}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280,
        },
      }}
      variant="temporary"
    >
      {children}
    </Drawer>
  )
}

import { useRole } from '../../contexts/PermissionContext'
import AccessDenied from '../AccessDenied'

const GlobalAccessGuard = ({ children }: React.PropsWithChildren) => {
  const { hasGame } = useRole()

  if (hasGame) {
    return <>{children}</>
  }
  return <AccessDenied />
}

export default GlobalAccessGuard

import { useMemo } from 'react'
import { useRole } from '../../contexts/PermissionContext'
import type { ListGamesInfoQuery } from '../../generated/graphql'
import { useListGamesInfoQuery } from '../../generated/graphql'

export type GamesList = ListGamesInfoQuery['findGames']

const useGames = (showAll = false) => {
  const { data } = useListGamesInfoQuery()
  const { isGameAvailable } = useRole()
  const games = useMemo(() => data?.findGames || [], [data])

  if (showAll) return games

  return games.filter((game) => isGameAvailable(game.id))
}

export default useGames

import type { CustomColors, Direction, ThemeOptions } from '@mui/material'
import { useTheme as muiUseTheme } from '@mui/material'
import { createTheme as createMuiTheme, responsiveFontSizes } from '@mui/material/styles'
import merge from 'lodash/merge'
import { darken, transparentize } from 'polished'
import { THEMES } from '../constants'
import { lightShadows } from './shadows'

interface ThemeConfig {
  direction?: Direction
  responsiveFontSizes?: boolean
  roundedCorners?: boolean
  theme?: string
}

const baseOptions: ThemeOptions = {
  direction: 'ltr',
  components: {
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px',
        },
      },
    },
  },
  typography: {
    button: {
      fontWeight: 700,
    },
    fontFamily: ['proxima-nova', 'sans-serif'].join(','),
    fontWeightRegular: 500,
    h1: {
      fontFamily: 'proxima-nova',
      fontSize: '3.5rem',
      fontWeight: 700,
    },
    h2: {
      fontFamily: 'proxima-nova',
      fontSize: '3rem',
      fontWeight: 700,
    },
    h3: {
      fontFamily: 'proxima-nova',
      fontSize: '2.25rem',
      fontWeight: 700,
    },
    h4: {
      fontFamily: 'proxima-nova',
      fontSize: '2rem',
      fontWeight: 700,
    },
    h5: {
      fontFamily: 'proxima-nova',
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    h6: {
      fontFamily: 'proxima-nova',
      fontSize: '1.125rem',
      fontWeight: 700,
    },
    overline: {
      fontWeight: 700,
    },
  },
}

const themesOptions: Record<string, ThemeOptions> = {
  [THEMES.MAIN]: {
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              opacity: 1,
              color: 'rgba(66, 82, 110, 0.86)',
            },
          },
        },
      },
      // @ts-ignore
      MuiDateTimePicker: {
        styleOverrides: {
          palette: {
            action: {
              active: 'rgb(207, 219, 140)',
            },
            text: {
              secondary: 'rgb(207, 219, 140)',
            },
          },
        },
      },
    },
    palette: {
      action: {
        // active: 'rgb(207, 219, 140)',
      },
      background: {
        default: '#F4F5F7',
        paper: 'rgb(255, 255, 255)',
      },
      mode: 'light',
      primary: {
        main: '#007391',
        contrastText: 'rgb(255, 255, 255)',
      },
      text: {
        primary: 'rgb(23, 43, 77)',
        secondary: 'rgb(107, 119, 140)',
      },
    },
    shadows: lightShadows,
  },
}

const customColors: CustomColors = {
  midAqua: '#96D6DE',
  liveGreen: '#39A935',
  finishedRed: '#E30613',
  anticipationOrange: '#F15A29',
  sustainBlue: '#00B2E8',
  draftGrey: '#656565',
  inactiveMapIcon: '#9e9e9e',
  activeMapIconBackground: '#f1f1f1',
  header: '#004851',
  headerDividers: transparentize(0.8, '#fff'),
  logoStandard: '#3aaa35',
  darkBlue: darken(0.05, '#004851'),
  lsoaBlue: 'rgba(0, 124, 191, 1)',
  init: 'rgb(193 87 0)',
  handled: 'rgb(57, 169, 53)',
  invalid: 'rgb(211, 47, 47)',
  duplicated: 'rgb(4 40 239)',
  unknown: 'rgb(80 101 116)',
}

export const createTheme = (config: ThemeConfig = {}) => {
  let themeOptions = themesOptions[config.theme || 'no theme']

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`))
    themeOptions = themesOptions[THEMES.MAIN]
  }

  let theme = createMuiTheme(
    merge(
      {},
      baseOptions,
      themeOptions,
      {
        ...(config.roundedCorners && {
          shape: {
            borderRadius: 16,
          },
        }),
      },
      {
        direction: config.direction,
      },
      {
        colors: customColors,
      },
    ),
  )

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme)
  }

  return theme
}

export const useTheme = () => muiUseTheme()

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import HelpOutlineIcon from '@mui/icons-material/InfoOutlined'
import { Card, CardContent, ListItem, ListItemIcon, ListItemText, Typography, Box } from '@mui/material'
import { BarChart, Bar, Tooltip, Legend, YAxis, XAxis, CartesianGrid, ResponsiveContainer } from 'recharts'
import TooltipIcon from '../../../../components/TooltipIcon'
import { useGetSwipesFrequenciesHourlyChartQuery } from '../../../../generated/graphql'
import useGame from '../../../../hooks/useGame'
import Dot from '../../../../icons/Dot'
import { useTheme } from '../../../../theme'

const CustomTooltip = ({ active, payload }) => {
  const theme = useTheme()
  if (active && payload && payload.length) {
    return (
      <Box sx={{ width: 180, backgroundColor: 'rgba(0, 0, 0, 0.75)', borderRadius: 1.5 }}>
        <ListItem
          disablePadding
          secondaryAction={
            <ListItemText
              primaryTypographyProps={{ variant: 'subtitle2', color: theme.palette.common.white }}
              primary={payload[0].value}
            />
          }
        >
          <ListItemIcon sx={{ borderRadius: 100, p: 0 }}>
            <Dot sx={{ color: payload[0].fill, zIndex: 1, fontSize: 32 }} />
            <Dot sx={{ position: 'absolute', zIndex: 0, color: '#ffffff', fontSize: 32 }} />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              variant: 'subtitle2',
              color: theme.palette.common.white,
              textTransform: 'capitalize',
            }}
            primary={payload[0].name}
          />
        </ListItem>
        <ListItem
          disablePadding
          secondaryAction={
            <ListItemText
              primaryTypographyProps={{
                variant: 'subtitle2',
                color: theme.palette.common.white,
              }}
              primary={payload[1].value}
            />
          }
        >
          <ListItemIcon>
            <Dot sx={{ color: payload[1].fill, fontSize: 32 }} />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              variant: 'subtitle2',
              color: theme.palette.common.white,
              textTransform: 'capitalize',
            }}
            primary={payload[1].name}
          />
        </ListItem>
      </Box>
    )
  }

  return null
}

const SwipesChart = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const game = useGame()

  const { data: swipeFrequencyHourlyData } = useGetSwipesFrequenciesHourlyChartQuery(
    { input: { gameId: game?.id } },
    { enabled: !!game?.id },
  )
  const swipesFrequenciesHourly = swipeFrequencyHourlyData?.adminGetSwipesFrequenciesHourlyChart

  const keys = useMemo(
    () => (swipesFrequenciesHourly ? Object.keys(swipesFrequenciesHourly) : []),
    [swipesFrequenciesHourly],
  )

  const dataArray = keys?.map((key) => ({
    name: t(`stats.swipesChart.${key}`),
    app: swipesFrequenciesHourly[key]?.app,
    card: swipesFrequenciesHourly[key]?.card,
  }))

  return (
    <Card
      elevation={1}
      sx={{
        borderRadius: 2,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflowX: 'scroll',
      }}
    >
      <Box sx={{ display: 'flex', p: 2 }}>
        <Typography color="inherit" variant="body2" textTransform={'uppercase'}>
          {t('stats.swipesByTimeOfDay')}
        </Typography>
        <Box sx={{ pl: 1 }}>
          <TooltipIcon
            text={t('stats.tooltips.swipesByTimeOfDay')}
            icon={<HelpOutlineIcon fontSize={'small'} sx={{ color: theme.palette.text.secondary }} />}
          />
        </Box>
      </Box>
      <CardContent
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <ResponsiveContainer width={'100%'} height={300} minWidth={550}>
          <BarChart
            data={dataArray}
            margin={{
              top: 5,
              right: 20,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              style={{
                fontFamily: theme.typography.fontFamily,
              }}
            />
            <YAxis
              style={{
                fontFamily: theme.typography.fontFamily,
              }}
            />
            <Tooltip content={CustomTooltip} />
            <Legend
              iconType={'circle'}
              wrapperStyle={{
                fontFamily: theme.typography.fontFamily,
                textTransform: 'uppercase',
              }}
            />
            <Bar dataKey="app" fill="rgba(86, 100, 210, 0.5)" />
            <Bar dataKey="card" fill="#5664D2" />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  )
}

export default SwipesChart

import { styled } from '@mui/material/styles'

const BackgroundContainer = styled('div')({
  backgroundColor: 'white',
  overflow: 'hidden',
  position: 'absolute',
  height: '1300px',
  width: '100%',
  top: 0,
  left: 0,
  backgroundImage: `linear-gradient(#96D6DE, white)`,
  backgroundSize: '100% 100%',
  backgroundRepeat: 'no-repeat',
  zIndex: 0,
})

export default BackgroundContainer

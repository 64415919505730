import { Drawer } from '@mui/material'
import { styled } from '@mui/material/styles'

const drawerWidth = 80

export const StyledDrawer = styled(Drawer)(({ open, theme }) => {
  if (!open) {
    return `
    & .MuiPaper-root {
      width: ${drawerWidth}px;
      transition: ${theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      })};
    }
    `
  }
  return `
      & .MuiPaper-root {
      transition: ${theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      })};
     overflow-x: 'hidden';
     width: 280px;
    }
    `
}) as typeof Drawer

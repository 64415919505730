import type { ReactNode } from 'react'
import { useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { GROUPS } from '../../constants'
import useAuth from '../../hooks/useAuth'
import Login from '../../pages/auth/Login'

interface AuthGuardProps {
  children?: ReactNode
  groups?: string[]
}

const AuthGuard = (props: AuthGuardProps) => {
  const { children, groups = [] } = props
  const auth = useAuth()
  const location = useLocation()
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null)

  const isAdmin = auth.user?.role?.isAdmin
  const isPartner = auth.user?.role?.isManager

  const allowAdmin = isAdmin && groups.includes(GROUPS.admin)
  const allowPartner = isPartner && groups.includes(GROUPS.partner)

  if (!auth.isAuthenticated || (!allowAdmin && !allowPartner)) {
    if ('/' !== location.pathname) {
      return <Navigate to={'/'} />
    }
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname)
    }

    return <Login />
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null)
    return <Navigate to={requestedLocation} />
  }

  return <>{children || <Outlet />}</>
}

export default AuthGuard

import { Tooltip } from '@mui/material'
import type { TooltipProps } from '@mui/material/Tooltip/Tooltip'

interface TooltipIconProps extends Partial<TooltipProps> {
  icon: React.ReactElement
  text: string
}
const TooltipIcon = ({ icon, text, ...props }: TooltipIconProps) => {
  return (
    <Tooltip title={text} arrow placement={'top-end'} {...props}>
      {icon}
    </Tooltip>
  )
}

export default TooltipIcon

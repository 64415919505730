import { format } from 'date-fns'

export const THEMES = {
  // LIGHT: 'LIGHT',
  // DARK: 'DARK',
  // NATURE: 'NATURE',
  MAIN: 'MAIN',
}

export const GROUPS = {
  admin: 'admin',
  partner: 'partner',
  installer: 'installer',
}

export const PHONE_NUMBER_REGEX =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const customDateFormat = (date: Date | string, time?: boolean) =>
  format(new Date(date), time ? 'd LLL y | HH:mm' : 'd LLL y')

export const PLAYERS_TABLE_FILTERS = {
  initialMinAge: 1,
  initialMaxAge: 99,
}

import type { ReactNode } from 'react'
import { Outlet } from 'react-router-dom'
import { styled } from '@mui/material/styles'

interface MainLayoutProps {
  children?: ReactNode
}

const MainLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '100%',
  paddingTop: 64,
}))

const MainLayout = ({ children }: MainLayoutProps) => {
  return <MainLayoutRoot>{children || <Outlet />}</MainLayoutRoot>
}

export default MainLayout

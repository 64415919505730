import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Button, FormHelperText, TextField } from '@mui/material'
import { Formik } from 'formik'
import * as Yup from 'yup'
import useAuth from '../../../hooks/useAuth'
import useIsMountedRef from '../../../hooks/useIsMountedRef'

const AmplifyLogin = (props) => {
  const isMountedRef = useIsMountedRef()
  const navigate = useNavigate()
  const { login } = useAuth()
  const { t } = useTranslation()

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().max(255).required(t('validation.password.req')),
        email: Yup.string()
          .legacyEmail(t('validation.email.valid'))
          .max(255)
          .required(t(t('validation.email.req'))),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }): Promise<void> => {
        try {
          const amplifyUser = await login(values.email, values.password)

          if (isMountedRef.current) {
            setStatus({ success: true })
            setSubmitting(false)
          }

          if (amplifyUser) {
            navigate('/new-password-challenge', {
              state: {
                user: JSON.stringify(amplifyUser),
              },
            })
          }
        } catch (err) {
          console.error(err)

          if (err.code === 'UserNotConfirmedException') {
            navigate('/verify-code', {
              state: {
                username: values.email,
              },
            })
            return
          }

          if (isMountedRef.current) {
            setStatus({ success: false })
            setErrors({ submit: t('errors.invalidCredentials') })
            setSubmitting(false)
          }
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={values.email}
            variant="outlined"
            inputProps={{
              'data-testid': 'email-input',
            }}
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label={t('labels.password')}
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
            inputProps={{
              'data-testid': 'password-input',
            }}
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit.toString()}</FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button color="primary" disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained">
              {t('auth.login')}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  )
}

export default AmplifyLogin
